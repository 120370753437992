import React from 'react';

const PrivacyPolicyPage = () => {
  return (
    <>
      <div className={'header-section-small'}>
        <h1>Privacy Policy</h1>
        <p>
          Welcome to Nuevas formas de pagar S.A de C.V. This privacy policy
          explains our policy regarding any information users may provide to us
          or that we may collect from you when you use our website, software, or
          services.
        </p>
        <h2>Information We Collect</h2>
        <p>
          To deliver our Services, we may collect Personal Information such as
          your name, email address, phone number, financial account information,
          transaction history, and other necessary financial information.
        </p>
        <h2>How We Use Information</h2>
        <p>
          We use the information we collect for several purposes including:
          providing and maintaining our Services, verifying identity, processing
          transactions, improving user experience, communicating with you, and
          compliance with legal requirements.
        </p>
        <h2>Information Sharing</h2>
        <p>
          Your privacy is important to us and we will not share your Personal
          Information with third parties except as necessary to deliver our
          Services, comply with the law, protect our rights, or with your
          express consent.
        </p>
        <h2>Data Security</h2>
        <p>
          We implement a variety of security measures to protect your Personal
          Information. These include standard encrypted protocols, secure
          networks, and limited access to your information.
        </p>
        <h2>Changes to This Policy</h2>
        <p>
          We may update our Privacy Policy from time to time. We will notify you
          of any changes by posting the new Privacy Policy on this page.
        </p>
        <h2>Contact Us</h2>
        <p>
          If you have any questions or suggestions about our Privacy Policy, do
          not hesitate to contact us at{' '}
          <a href=' https://receipts.wefine.app/support'>
            https://receipts.wefine.app/support
          </a>
          .
        </p>
      </div>
    </>
  );
};

export default PrivacyPolicyPage;
