import React from 'react';

const SupportPage = () => {
  // Replace the following variables with your actual contact information
  const phone = '+525513734898';
  const supportEmail = 'jesus.gonzalez.xcv@gmail.com';
  const genericMexicanAddress = '123 Alvaro obregon, Mexico City, MX 06700';

  return (
    <>
      <div className={'header-section-small'}>
        <h1>Contact Support</h1>
        <p>
          If you need any assistance or have questions, our support team is here
          to help!
        </p>
        <div className='button-section'>
          <a href={`tel:${phone}`}>Call Support (+525513734898)</a>

          <a href={`mailto:${supportEmail}`}>
            Email Support (jesus.gonzalez.xcv@gmail.com)
          </a>
        </div>
        <p>Or visit our office:</p>
        <p>{genericMexicanAddress}</p>
      </div>
    </>
  );
};

export default SupportPage;
