import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import NotFound from '../../NotFound/NotFound';
import BGHeader from '../../../assets/bg-header.webp';
import AppStore from '../../../assets/appstore.png';
import PlayStore from '../../../assets/playstore.png';

const TransactionStatusPage = ({}) => {
  const { id } = useParams();
  const [transaction, setTransaction] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchTransaction = async () => {
      try {
        const response = await fetch(
          `https://backend.dev.wefine.app/api/v1/transactions/${id}`,
          {
            method: 'GET',
            headers: {
              Accept: 'application/json',
            },
          }
        );
        const data = await response.json();
        if (!response.ok) {
          setError(data.detail);
        }
        setTransaction(data);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };
    fetchTransaction();
  }, [id]);

  function renderPayment() {
    const {
      recipient_account_number,
      transaction_code,
      amount,
      payment_status,
      payment_date,
      beneficiary_name,
      id: transaction_id,
    } = transaction;
    return (
      <div
        className={`transaction-status-page ${transaction ? 'visible' : ''}`}
      >
        <div className='transaction-status-header'>
          <h1>Transaction Status</h1>
          <p>{payment_status}</p>
        </div>
        <div className='transaction-status-body'>
          <div className='amount'>
            <p>Amount</p>
            <h2>{formatCurrency(amount)}</h2>
          </div>
          <div className='recipient-account'>
            <p>Recipient Account</p>
            <h2>{recipient_account_number}</h2>
          </div>
          <div className='recipient-account'>
            <p>Beneficiary</p>
            <h2>{beneficiary_name}</h2>
          </div>
          <div className='transaction-code'>
            <p>Transaction Code</p>
            <h2>{transaction_code}</h2>
          </div>
          <div className='payment-date'>
            <p>Payment Date</p>
            <h2>{formatDate(payment_date)}</h2>
          </div>
          <div className='transaction-id' onClick={copyTransactionId}>
            <p>Transaction ID:</p>
            <h2>{transaction_id}</h2>
          </div>
        </div>
      </div>
    );
  }

  const formatCurrency = (amount) => {
    const formatter = new Intl.NumberFormat('es-MX', {
      style: 'currency',
      currency: 'MXN',
      minimumFractionDigits: 2,
    });
    return formatter.format(amount);
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString() + ' ' + date.toLocaleTimeString();
  };

  const copyTransactionId = () => {
    const el = document.createElement('textarea');
    el.value = id;
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
  };

  if (error) {
    return <div>Error: Transacción no encontrada</div>;
  } else {
    return (
      <>
        <div
          className={`header-section ${!loading ? 'header-section-small' : ''}`}
        >
          <h1>
            {`${transaction?.sender_name} used SoFine to make this payment in 5
            seconds`}
          </h1>
          <p>Get SoFine now to start making payments quickly and easily!</p>
          <div className='button-section'>
            <a href='https://play.google.com/store/apps/details?id=com.yourappname'>
              <img src={PlayStore} alt='Google Play Store' />
            </a>
            <a href='https://itunes.apple.com/us/app/yourappname/id1234567890?ls=1&mt=8'>
              <img src={AppStore} alt='Apple App Store' />
            </a>
          </div>
        </div>
        {!loading && transaction ? renderPayment() : <></>}
      </>
    );
  }
};

export default TransactionStatusPage;
