import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import TransactionStatusPage from './pages/Transaction/TransactionStatus/TransactionStatus';
import SupportPage from './pages/Support/Support';
import NotFound from './pages/NotFound/NotFound';
import PrivacyPolicyPage from './pages/Privacy/Privacy';

const App = () => {
  return (
    <Router>
      <Routes>
        <Route path='/' element={<SupportPage />} />
        <Route path='/support' element={<SupportPage />} />
        <Route path='/privacy' element={<PrivacyPolicyPage />} />
        <Route path='/transaction/:id' element={<TransactionStatusPage />} />
        <Route path='*' element={<SupportPage />} />
      </Routes>
    </Router>
  );
};

export default App;
